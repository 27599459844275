import React, { useState, useEffect } from 'react';

import cx from 'classnames';
import useFeature from 'hooks/useFeature';
import useIsEligibleForResearchPanel from 'hooks/useIsEligibleForResearchPanel';
import { List } from 'immutable';
import assign from 'lodash/assign';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as AttributesActions from 'actions/attributes';
import { unauthenticate } from 'actions/authenticate';
import { getSummary } from 'actions/summary';
import ThankYouPage from 'components/ThankYouPage';
import * as stateUtil from 'lib/stateUtil';

import MobileSMSAuthThankYou from './MobileSMSAuthThankYou';
import styles from './ThankYou.scss';

const KIOSK_COUNT_DOWN_SECONDS = 7;
const KIOSK_MOBILE_SMS_COUNT_DOWN_SECONDS = 120;

function useCountDown(startSeconds) {
  const [seconds, setSeconds] = useState(startSeconds);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => {
        try {
          setSeconds(seconds - 1);
        } catch (e) {
          // no-op, in case the components unmounts
        }
      }, 1000);
    }
  }, [seconds, setSeconds]);

  return seconds;
}

export function KioskThankYouPage({ scheduleCode, logo, contextName, logout }) {
  const seconds = useCountDown(KIOSK_COUNT_DOWN_SECONDS);
  const { t } = useTranslation();

  useEffect(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    if (seconds === 0) {
      window.location.assign(`/survey/kiosk/${scheduleCode}`);
    }
  }, [seconds, scheduleCode]);

  return (
    <div className={cx(styles.root, styles.kiosk)}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {logo && (
            <div className={styles.logoWrapper}>
              <img
                src={`${logo}?fit=max&w=100&h=100&dpr=2`}
                alt={contextName}
              />
            </div>
          )}
          <h1 className={styles.title}>{t('survey__thanks__thank_you')}</h1>
          <p className={styles.text}>{t('survey__kiosk__thanks')}</p>
          <p className={styles.kioskInfo}>
            {seconds > 1
              ? t('survey__kiosk__go_away', {
                  seconds,
                })
              : t('survey__kiosk__go_away_second')}
          </p>
        </div>
      </div>
    </div>
  );
}

KioskThankYouPage.propTypes = {
  contextName: PropTypes.string,
  logo: PropTypes.string,
  scheduleCode: PropTypes.string,
  logout: PropTypes.func,
};

export function ThankYou({
  isKiosk,
  isNative,
  isRenderedInIframe,
  scheduleCode,
  summary,
  isPreview,
  addOns = new List(),
  locale,
  actions,
  user,
  session,
  contextName,
  logo,
}) {
  const isEligibleForResearchPanel = useIsEligibleForResearchPanel(
    summary,
    addOns,
    locale,
  );

  useEffect(() => {
    !isKiosk && summary.enabled && summary.isLoading && actions.getSummary();
  }, [isKiosk, summary, actions]);

  useEffect(() => {
    const summaryIsLoading = summary.enabled && summary.isLoading;

    if (
      !summaryIsLoading &&
      addOns.size > 0 && // make sure addOns have loaded
      !isEligibleForResearchPanel &&
      !isPreview
    ) {
      actions.unauthenticate();
    }
  }, [summary, actions, addOns, locale, isPreview, isEligibleForResearchPanel]);

  const [hasBetaMobileSMSAuth] = useFeature('mobileSMSAuthentication');
  const seconds = useCountDown(
    isKiosk ? KIOSK_MOBILE_SMS_COUNT_DOWN_SECONDS : 0,
  );

  if (hasBetaMobileSMSAuth && !isNative) {
    const hasEmployeeDashboard = get(session, 'rights', []).includes(
      'employeeDashboard:access',
    );

    return (
      <MobileSMSAuthThankYou
        seconds={seconds}
        hasEmployeeDashboard={hasEmployeeDashboard}
        withTimer={isKiosk}
        scheduleCode={scheduleCode}
      />
    );
  }

  if (isKiosk) {
    return (
      <KioskThankYouPage
        scheduleCode={scheduleCode}
        contextName={contextName}
        logo={logo}
        logout={actions.unauthenticate}
      />
    );
  }

  return (
    <div className={styles.thankYouWrapper}>
      <ThankYouPage
        user={user}
        summary={summary}
        session={session}
        isNative={isNative}
        isRenderedInIframe={isRenderedInIframe}
        actions={actions}
        addOns={addOns}
        locale={locale}
        contextName={contextName}
      />
    </div>
  );
}

ThankYou.propTypes = {
  actions: PropTypes.object.isRequired,
  contextName: PropTypes.string,
  user: PropTypes.object,
  isNative: PropTypes.bool,
  isRenderedInIframe: PropTypes.bool,
  logo: PropTypes.string,
  isKiosk: PropTypes.bool,
  scheduleCode: PropTypes.string,
  summary: PropTypes.object,
  isPreview: PropTypes.bool,
  addOns: PropTypes.instanceOf(List),
  session: PropTypes.object,
  locale: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    contextName: state.getIn(['survey', 'context', 'name']),
    logo: state.getIn(['survey', 'context', 'logo']),
    user: stateUtil.toJS(['authenticate', 'user'], state),
    isNative: state.getIn(['ui', 'isNative']),
    isRenderedInIframe: state.getIn(['ui', 'isRenderedInIframe']),
    isKiosk: state.getIn(['survey', 'isKiosk']),
    scheduleCode: state.getIn(['survey', 'scheduleCode']),
    summary: state.get('summary'),
    isPreview: state.getIn(['survey', 'isPreview']),
    addOns: state.getIn(['authenticate', 'company', 'attributes', 'addOns']),
    session: stateUtil.toJS(['authenticate', 'session'], state),
    locale: state.getIn(['locale', 'locale']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      assign(
        {
          getSummary,
          unauthenticate,
        },
        AttributesActions,
      ),
      dispatch,
    ),
  };
};

export const ThankYouContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThankYou);
