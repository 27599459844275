import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { AccessLockIcon as LockIcon } from '@peakon/bedrock/icons/system';
import { LinkButton } from '@peakon/bedrock/react/button';

import Text from 'components/Text';
import getWebHost from 'utils/getWebHost';

import styles from './styles.scss';

function PersonalDashboardCta({ activated, loginHref }) {
  return (
    <Fragment>
      {!activated && (
        <h2 className={styles.activateCta}>
          <Text>employee_dashboard__v2__activate_cta</Text>
        </h2>
      )}
      <div className={styles.root}>
        <LinkButton
          variant="primary"
          href={loginHref}
          data-test-id="handle-login-btn"
        >
          {activated ? (
            <Text>employee_dashboard__upsell_cta_activated</Text>
          ) : (
            <Text>employee_dashboard__upsell_cta</Text>
          )}
        </LinkButton>

        <div className={styles.learnMore}>
          <LockIcon />
          <span>
            <Text id="learn-link-description">
              employee_dashboard__about_desc__one
            </Text>{' '}
            <a
              href={`${getWebHost()}/what-is-peakon`}
              rel="noopener noreferrer"
              target="_blank"
              id="learn-link-more"
              aria-describedby="learn-link-more learn-link-description"
            >
              <Text>employee_dashboard__v2__learn_more</Text>
            </a>
          </span>
        </div>
      </div>
    </Fragment>
  );
}

PersonalDashboardCta.propTypes = {
  activated: PropTypes.bool,
  loginHref: PropTypes.string,
};

export default PersonalDashboardCta;
