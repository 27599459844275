import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AccessLockIcon } from '@peakon/bedrock/icons/system';
import { Button, LinkButton } from '@peakon/bedrock/react/button';
import { Heading1, Paragraph } from '@peakon/bedrock/react/typography';
import { PhoneField } from '@peakon/components';
import countryLabels from '@peakon/components/dist/PhoneField/data/countryLabels';

import { postGenerateCode, sendSMSReset } from 'actions/authenticate';

import downloadPeakonAppQRcode from './assets/downloadPeakonApp.png';
import smsIllustration from './assets/smsIllustration.svg';
import styles from './MobileSMSAuthThankYou.scss';

const MobileSMSAuthThankYou = ({
  seconds,
  hasEmployeeDashboard,
  withTimer,
  scheduleCode,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isSendSMSSuccess = useSelector((state) => {
    return state.getIn(['authenticate', 'isSendSMSSuccess']);
  });

  const [phoneNumber, setPhoneNumber] = useState({
    number: '',
    isValidNumber: false,
  });

  useEffect(() => {
    if (withTimer && seconds === 0) {
      window.location.assign(`/survey/kiosk/${scheduleCode}`);
    }
  }, [seconds, withTimer, scheduleCode]);

  useEffect(() => {
    return () => {
      sendSMSReset();
    };
  }, []);

  const renderCTADescription = () => {
    return (
      <div className={cx(styles.ctaDescription, styles.desktopSection)}>
        <Paragraph size="small">
          {t('survey__kiosk__mobile_sms_auth__cta_desc')}
        </Paragraph>
      </div>
    );
  };

  const renderCountdown = () => {
    return (
      <div className={cx(styles.countdown, styles.desktopSection)}>
        <Paragraph variant="hint">
          {seconds > 1
            ? t('survey__kiosk__go_away', {
                seconds,
              })
            : t('survey__kiosk__go_away_second')}
        </Paragraph>
      </div>
    );
  };

  const handleChangePhoneNumber = (value) => {
    dispatch(sendSMSReset());
    setPhoneNumber(value);
  };

  const handleClickSendSMS = async () => {
    dispatch(postGenerateCode(phoneNumber.number));
  };

  return (
    <div className={cx(styles.root, styles.kiosk)}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className={cx(styles.title, styles.mobileSMSAuthTitle)}>
            {t('survey__kiosk__mobile_sms_auth__thanks')}
          </h1>

          <Heading1 level={2}>
            {hasEmployeeDashboard
              ? t(
                  'survey__kiosk__mobile_sms_auth__title_with_personal_dashboard',
                )
              : t('survey__kiosk__mobile_sms_auth__title')}
          </Heading1>

          <div>
            <img
              alt=""
              className={styles.smsIllustration}
              src={smsIllustration}
              width="360"
              height="180"
            />
          </div>
          <Paragraph>
            {hasEmployeeDashboard
              ? t(
                  'survey__kiosk__mobile_sms_auth__desc_with_personal_dashboard',
                )
              : t('survey__kiosk__mobile_sms_auth__desc')}
          </Paragraph>

          <div className={styles.disclaimer}>
            <AccessLockIcon />
            <small>{t('survey__kiosk__mobile_sms_auth__disclaimer')}</small>
          </div>

          <div className={styles.mobileCTASection}>
            <LinkButton
              variant="primary"
              href="https://app.peakon.com/mobile/redirect"
              fullWidth
            >
              {t('survey__download_the_app_cta')}
            </LinkButton>
            {renderCTADescription()}
            {withTimer && renderCountdown()}
          </div>
        </div>
        <div className={styles.desktopContent}>
          <div className={cx(styles.scanText, styles.desktopSection)}>
            <Paragraph>{t('survey__scan_the_qr_code')}</Paragraph>
          </div>
          <img
            alt="QR code for downloading the Peakon app"
            width={160}
            height={160}
            className={styles.qrCode}
            src={downloadPeakonAppQRcode}
          />
          <div className={cx(styles.sendLinkText, styles.desktopSection)}>
            <Paragraph size="small" variant="hint">
              {t('survey__kiosk__mobile_sms_auth__send_link')}
            </Paragraph>
          </div>

          <div
            className={cx(styles.phoneFieldContainer, styles.desktopSection)}
          >
            <div className={styles.phoneFieldWrapper}>
              <PhoneField
                label={t('survey__kiosk__mobile_sms_auth__phone_number')}
                onChange={handleChangePhoneNumber}
                countryLabels={countryLabels}
                dialCodeLabel={t('survey__kiosk__mobile_sms_auth__dial_code')}
                className={styles.phoneField}
              />

              <Button
                variant="secondary"
                onClick={handleClickSendSMS}
                disabled={!phoneNumber.isValidNumber}
              >
                {t('survey__kiosk__mobile_sms_auth__send')}
              </Button>
            </div>
            {isSendSMSSuccess && (
              <p className={styles.successText}>
                {t('survey__kiosk__mobile_sms_auth__send__success')}
              </p>
            )}
          </div>

          {renderCTADescription()}
          {withTimer && renderCountdown()}
        </div>
      </div>
    </div>
  );
};

MobileSMSAuthThankYou.propTypes = {
  seconds: PropTypes.number.isRequired,
  hasEmployeeDashboard: PropTypes.bool,
  withTimer: PropTypes.bool,
  scheduleCode: PropTypes.string,
};

export default MobileSMSAuthThankYou;
